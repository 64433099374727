/* eslint-disable unused-imports/no-unused-vars */
import React from "react";
import styled, { css } from "styled-components";
import NextLink from "next/link";
import MagicLink from "../components/MagicLink";
import { pulsate, fadeIn } from "../helpers/animations";

import { variables, media } from "../global-styles";

// TEMP
// Simple filter to remove unwanted props from the native <a> element
// -> https://github.com/styled-components/styled-components/issues/439
const ButtonLinkFilter = ({
  secondary,
  disabled,
  text,
  full,
  small,
  light,
  routerLink,
  ...props
}) => <MagicLink {...props} />;

// Ripple effect mixin
// function ripple(backgroundColor, backgroundColorHover, backgroundColorRipple) {
//   return `
//     background-color: ${backgroundColor};
//     &:hover {
//       background-color: ${props => props.theme.primary.green};
//       color: ${props => props.theme.secondary.light};
//       border-color: ${props => props.theme.primary.green};
//     }
//     &:active {
//       background-color: ${backgroundColorHover};
//       background-size: 100%;
//       transition: background 0s;
//     }
//   `;
// }

const Button = styled.button`
  display: inline-block;
  padding: ${variables.buttonPadding};

  border-radius: 2px;
  border-width: 1px !important;

  font-weight: 600;
  letter-spacing: 0.02em;
  min-width: 58px;
  min-height: 48px;
  height: 36px;
  line-height: 46px;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;

  cursor: pointer;

  transition: all 0.2s ease-in-out;

  background-color: ${(props) => props.theme.primary.green1} !important;

  border: 1px solid ${(props) => props.theme.primary.green1};
  color: ${(props) => props.theme.colors.primaryButtonText};
  opacity: 1;

  &:hover {
    transition: all 0.2s ease-in-out;
    opacity: 0.9;
  }

  /* Small variant */
  ${(props) =>
    props.small &&
    css`
      font-size: 12px !important;
      padding: 8px !important;
      min-width: fit-content;
      min-height: fit-content;
      height: fit-content;
      line-height: normal;
      text-transform: none;
      border: 1px solid #000000 !important;
      &:hover {
        border: 1px solid ${(props) => props.theme.primary.green1} !important;
      }
      &:active {
        border: 1px solid #000000 !important;
      }
    `};

  ${(props) =>
    props.smallNormal &&
    css`
      font-size: 12px !important;
      padding: 8px !important;
      min-width: fit-content;
      min-height: fit-content;
      height: fit-content;
      line-height: normal;
    `};

  /* Wide variant */
  ${(props) =>
    props.wide &&
    css`
      padding: 0px 94px;
    `};

  /* Small variant */
  ${(props) =>
    props.light &&
    css`
      background-color: ${(props) => props.theme.secondary.light} !important;
      color: ${(props) => props.theme.secondary.dark};
      border: 1px solid ${(props) => props.theme.primary.green1};
      border-radius: 2px;

      &:hover {
        color: ${(props) => props.theme.secondary.light};
        background-color: ${(props) => props.theme.primary.green1} !important;
      }
    `};

  /* Secondary variant */
  ${(props) =>
    props.secondary &&
    css`
      background-color: ${(props) => props.theme.secondary.light} !important;
      color: ${(props) => props.theme.secondary.dark};
      border: 1px solid ${(props) => props.theme.primary.green1};
      &:hover {
        color: ${(props) => props.theme.secondary.light};
        background-color: ${(props) => props.theme.primary.green1} !important;
      }
    `};

  /* Transparent variant  */
  ${(props) =>
    props.transparent &&
    css`
      color: ${props.theme.colors.secondaryLightGreenButtonText};
      &:hover {
        background-color: ${props.theme.colors.secondaryGreyButtonHover};
      }
    `};

  /* Disabled variant */
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${(props) => props.theme.secondary.gray};
      background-color: ${(props) =>
        props.theme.secondary.lightGray} !important;
      border: none;
      &:hover {
        cursor: not-allowed;
        color: ${(props) => props.theme.secondary.gray};
        background-color: ${(props) =>
          props.theme.secondary.lightGray} !important;
        border: none;
      }
    `};

  ${(props) =>
    props.slim &&
    css`
      padding: ${variables.slimButtonPadding};
    `};

  ${(props) =>
    props.circle &&
    css`
      padding: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;

      /* Media queries */
      ${media.baby`
        width: 60px;
        height: 60px;
      `};
    `};

  /* Full-width variant */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};

  /* Full-width variant */
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.medium &&
    css`
      min-width: 157px;
    `};

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `};

  ${(props) =>
    props.animate &&
    css`
      animation: ${pulsate} ${props.animate} linear infinite;
    `};

  /* Full-width variant */
  ${(props) =>
    props.fadeIn &&
    css`
      animation ${fadeIn} 0.5s ease-in-out 1 both;
    `};

  /* Full-width variant */
  ${(props) =>
    props.warning &&
    css`
      background-color: ${(props) => props.theme.secondary.yellow2} !important;
    `};

  ${(props) =>
    props.borderless &&
    css`
      border: none;
    `}

  ${(props) =>
    props.outlined &&
    css`
      border: 1px solid white;
      background-color: rgba(0, 0, 0, 0);
    `}


    ${(props) =>
    props.new &&
    css`
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      min-height: auto;
      min-width: auto;
      height: auto;
      width: auto;
      line-height: 21px;
      padding: 7.5px 12px !important;
      border: none;
      color: white;
      text-transform: none;
    `}
`;

const ButtonLink = Button;
const ButtonFake = Button;
const ButtonRouterLink = Button;

const ButtonLinkNext = ({ href, as, children, ...props }) => {
  return (
    <NextLink href={href} as={as} passHref legacyBehavior>
      <ButtonLink {...props}>{children}</ButtonLink>
    </NextLink>
  );
};

export { Button, ButtonLink, ButtonFake, ButtonRouterLink, ButtonLinkNext };
