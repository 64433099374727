/* eslint-disable unused-imports/no-unused-vars */
import React from "react";
import styled, { css } from "styled-components";
import NextLinkComponent from "next/link";

import { variables } from "../global-styles";

const activeClassName = "nav-item-active";

// prettier-ignore
// eslint-disable-next-line
const aFilter = ({ textlink, noUnderline, isPrimary, small, activeClassName, blackUnderline, extraSmall, ...props }) =>
  <a {...props} />;

// eslint-disable-next-line
const spanFilter = ({
  textlink,
  noUnderline,
  isPrimary,
  small,
  activeClassName,
  blackUnderline,
  ...props
}) => <span {...props} />;

const LinkComponent = styled.div.attrs({
  activeClassName,
})`
  display: inline;
  letter-spacing: 1.25px;
  color: currentColor;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
  border-bottom: ${variables.borderWidth} solid
    ${(props) => props.theme.colors.linkUnderlineWeak};

  /* Active version */
  &.${activeClassName} {
    font-weight: 600;
  }

  /* link integrated into text */
  ${(props) =>
    props.textlink &&
    css`
      color: ${(props) => props.theme.colors.textLink};
      line-height: 20px;
      letter-spacing: normal;
    `};

  /* No underline variant */
  ${(props) =>
    props.noUnderline &&
    css`
      border: 0;
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  ${(props) =>
    props.tight &&
    css`
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;

      font-feature-settings:
        "pnum" on,
        "lnum" on,
        "liga" off;
    `};

  /* Primary underline variant */
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.primary.green};
      border-bottom: ${variables.borderWidth} solid ${props.theme.primary.green};
    `};

  /* Slim */
  ${(props) =>
    props.slim &&
    css`
      border-bottom: 1px solid ${props.theme.primary.green} !important;
    `};

  /* Primary underline variant */
  ${(props) =>
    props.smallFont &&
    css`
      font-size: 10px !important;
      font-style: normal;
      line-height: 14px;
      letter-spacing: 0.02em;
      font-feature-settings:
        "pnum" on,
        "lnum" on,
        "liga" off;
    `};

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => props.theme.primary.green};
    `};

  ${(props) =>
    props.blackUnderline &&
    css`
      border-bottom: ${variables.borderWidth} solid black;
    `};
  ${(props) =>
    props.primaryUnderline &&
    css`
      border-bottom: ${variables.borderWidth} solid
        ${props.theme.colors.green600};
    `};

  /* Small variant */
  ${(props) =>
    props.small &&
    css`
      font-size: 14px !important;
      letter-spacing: 1 !important;
    `};

  ${(props) =>
    props.extraSmall &&
    css`
      font-size: 10px !important;
      line-height: 14px;
    `};

  ${(props) =>
    props.caption &&
    css`
      font-size: 12px !important;
      letter-spacing: 1 !important;
    `};

  ${(props) =>
    props.hover &&
    css`
      border-radius: 2px;
      padding: 7px;
      &:hover {
        background-color: ${props.theme.colors.gray200};
      }
    `};

  ${(props) =>
    props.upperCase &&
    css`
      text-transform: uppercase;
    `};

  ${(props) =>
    props.thick &&
    css`
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      border-bottom: 1px solid black;
    `};

  ${(props) =>
    props.semiBold &&
    css`
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
    `};
`;

const FakeLink = LinkComponent;
const ExternalLink = LinkComponent;

const NextLink = ({ href, as, children, ...props }) => (
  <NextLinkComponent href={href} as={as} passHref legacyBehavior>
    <ExternalLink {...props}>{children}</ExternalLink>
  </NextLinkComponent>
);

const Link = NextLink;

export { Link, ExternalLink, FakeLink, NextLink };
