import styled, { css } from "styled-components";

import variables from "../constants/variables";
import { media } from "../global-styles";

const Card = styled.div`
  background: #ffffff;
  position: relative;
  padding: ${variables.spacing[4]};

  @media ${variables.screen.medium} {
    padding: ${variables.spacing[7]};
    border: 1px solid #e9e9e9;
    border-radius: 2px;
  }
  /* Modifiers */

  ${(props) =>
    props.noBorder &&
    css`
      border: 0 !important;
    `};

  ${(props) =>
    props.noBorderMobile &&
    css`
      border: 0;
      ${media.child`
        border: 1px solid #e9e9e9;
      `};
    `};

  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `};

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `};

  ${(props) =>
    props.morePadding &&
    css`
      padding: ${variables.spacing[6]};

      @media ${variables.screen.medium} {
        padding: ${variables.spacing[7]};
      }
    `};

  ${(props) =>
    props.noPaddingMobile &&
    css`
      @media ${variables.screen.mediumMax} {
        padding: 0 !important;
      }
    `};

  ${(props) =>
    props.halfPadding &&
    css`
      padding: ${variables.spacing[4]} !important;
    `};

  ${(props) =>
    props.minHeight &&
    css`
      @media ${variables.screen.medium} {
        min-height: ${props.minHeight};
      }
    `};

  ${(props) =>
    props.borderTopMobile &&
    css`
      @media ${variables.screen.mediumMax} {
        padding-top: ${variables.spacing[6]} !important;
        border-top: 1px solid ${(props) => props.theme.secondary.lightGray};
      }
    `};

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
    `};

  ${(props) =>
    props.greenBackground &&
    css`
      background-color: ${(props) => props.theme.primary.green4}};
    `};

  ${(props) =>
    props.noBorderRadius &&
    css`
      border-radius: 0px;
    `};

  ${(props) =>
    props.shadow &&
    css`
      box-shadow:
        0px 2px 2px rgba(0, 0, 0, 0.08),
        0px 4px 12px rgba(53, 55, 58, 0.08);
    `};
`;

export default Card;
