import styled, { css } from "styled-components";

import { variables, media } from "../global-styles";
import { customBreakpoints } from "../constants/breakpoints";
// The size map decides what gutters/spacing the Block gets
const sizeMap = {
  mobile: {
    horizontal: {
      1: variables.gutterTightMobile,
      2: variables.gutterMediumMobile,
      3: variables.gutterLargeMobile,
    },
    vertical: {
      1: variables.spacingTightMobile,
      2: variables.spacingSmallMobile,
      3: variables.spacingMediumMobile,
      4: variables.spacingLargeMobile,
      5: variables.spacingXLargeMobile,
    },
  },
  desktop: {
    horizontal: {
      1: variables.gutterTight,
      2: variables.gutterMedium,
      3: variables.gutterLarge,
    },
    vertical: {
      1: variables.spacingTight,
      2: variables.spacingSmall,
      3: variables.spacingMedium,
      4: variables.spacingLarge,
      5: variables.spacingXLarge,
    },
  },
};

function calcGutter(type, size = 0, breakpoint = "mobile") {
  switch (type) {
    case "left":
    case "right":
      return sizeMap[breakpoint].horizontal[size] || 0;
    case "top":
    case "bottom":
      return sizeMap[breakpoint].vertical[size] || 0;
    default:
      return 0;
  }
}

const Block = styled.div`
  /* Full height */
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `};

  /* Left margin */
  ${(props) =>
    props.left &&
    css`
      margin-left: ${calcGutter("left", props.left)};
      ${media.teen`
        margin-left: ${calcGutter("left", props.left, "desktop")};
      `};
    `};

  /* Right margin */
  ${(props) =>
    props.right &&
    css`
      margin-right: ${calcGutter("right", props.right)};
      ${media.teen`
        margin-right: ${calcGutter("right", props.right, "desktop")};
      `};
    `};

  /* Top padding */
  ${(props) =>
    props.top &&
    css`
      padding-top: ${calcGutter("top", props.top)};
      ${media.teen`
        padding-top: ${calcGutter("top", props.top, "desktop")};
      `};
    `};

  /* Fixed Top padding */
  ${(props) =>
    props.ftop &&
    css`
      padding-top: ${props.ftop};
    `};

  /* Bottom padding */
  ${(props) =>
    props.bottom &&
    css`
      padding-bottom: ${calcGutter("bottom", props.bottom)};
      ${media.teen`
        padding-bottom: ${calcGutter("bottom", props.bottom, "desktop")};
      `};
    `};

  /* fixed Bottom padding */
  ${(props) =>
    props.fbottom &&
    css`
      padding-bottom: ${props.fbottom};
    `};

  /* A simple helper that only gives 'position: relative' to the block */
  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `};

  ${(props) =>
    props.site &&
    css`
      margin: 0 auto;
      padding-left: ${variables.gutter2Mobile};
      padding-right: ${variables.gutter2Mobile};
      @media${customBreakpoints.onlySmallScreen} {
        padding-left: ${variables.gutter2SmallMobile};
        padding-right: ${variables.gutter2SmallMobile};
      }
      @media (min-width: 1024px) {
        max-width: 1440px;
      }
    `};

  /* Same as 'Site Block' with mobile padding */
  ${(props) =>
    props.centeredMobile &&
    css`
      position: relative;
      padding-left: calc(${variables.gutterLarge} / 2);
      padding-right: calc(${variables.gutterLarge} / 2);
      margin: 0;
      width: 100%;
    `};

  ${(props) =>
    props.mediumSize &&
    css`
      margin: 0 auto;
      position: relative;
      padding-left: ${variables.gutterMediumMobile};
      padding-right: ${variables.gutterMediumMobile};
      ${media.teen`
      padding-left: ${variables.gutterLarge};
      padding-right: ${variables.gutterLarge};

      `};
    `};

  /* Text align settings */
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `};

  /* Inline version */
  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `};

  /* Full width version */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};

  /* Centered version */
  ${(props) =>
    props.autocenter &&
    css`
      margin: 0 auto;
    `};

  /* Limited width version */
  ${(props) =>
    props.limitWidth &&
    css`
      max-width: ${props.limitWidth};
    `};

  /* Top layer version */
  ${(props) =>
    props.topLayer &&
    css`
      z-index: 2;
    `};

  /* White bg version */
  ${(props) =>
    props.white &&
    css`
      background-color: white;
    `};

  ${(props) =>
    props.brightYellow &&
    css`
      background-color: ${props.theme.colors.yellow25};
    `};

  /* Grey bg version */
  ${(props) =>
    props.grey &&
    css`
      background-color: #efefef;
    `};

  /* White bg version */
  ${(props) =>
    props.primary &&
    css`
      color: white;
      background-color: ${props.theme.colors.primary};
    `};

  /* Primary color */

  ${(props) =>
    props.primaryColor &&
    css`
      color: ${props.theme.primary.green};
    `};

  /* custom background version */
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.theme.colors[props.backgroundColor]};
    `};

  ${(props) =>
    props.bg &&
    css`
      background-color: ${props.theme.colors.bg};

      ${media.teen`
        ${
          props.site &&
          css`
            &::before {
              content: "";
              background-color: ${props.theme.colors.bg};
              position: absolute;
              top: 0;
              bottom: 0;
              left: -4vw;
              right: -4vw;
              z-index: -1;
            }
          `
        };
      `};
    `};

  /* White bg version */
  ${(props) =>
    props.hideOverflow &&
    css`
      overflow: hidden;
    `};
  ${(props) =>
    props.showOverflow &&
    css`
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    `};

  /* Enable stickiness */
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: 0px;
      backface-visibility: hidden;
    `};

  /* background image */
  ${(props) =>
    props.img &&
    css`
      height: 500px;
      background-image: url("${props.img}");
      background-size: cover;
      ${media.infant`
        height: 500;
      `};
      ${media.teen`
        height: 600px;
      `};
      ${media.adult`
        height: 700px;
      `};
    `};
  /* custom background version */
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.theme.colors.gray200};
      border-radius: 2px;
    `};

  /* White bg version */
  ${(props) =>
    props.opacity &&
    css`
      opacity: 1;
      transition: all 0.2s ease-in-out;
      &:hover {
        opacity: 0.6;
      }
    `};

  ${(props) =>
    props.fullPage &&
    css`
      height: 100vh;
    `}
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.secondary.gray};
    `};
`;
const InlineBlock = Block;
export { Block, InlineBlock };

export default Block;
